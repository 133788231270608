<template lang="pug">
.circle-number(v-if="days && days >= 0" :class="myBackground()")
  .circle-number__content {{ days >= 99 ? '99+' : days }}
</template>

<script lang="ts">
import { getDaysUntil } from "@/utils/formatter/dateFormatter";
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "CircleNumber",
  props: {
    dateTo: {
      type: String,
      default: '',
    },
  },
  setup(props) {

    const days = computed<number>(() => props.dateTo ? getDaysUntil(props.dateTo) : 0)

    function myBackground(): string {
      if (days.value < 0) return "_grey";
      if (days.value <= 2) return "_danger";
      if (days.value <= 4) return "_warning";
      if (days.value >= 5) return "_success";
      return "_grey";
    }

    return {
      days,
      myBackground,
    };
  },
});
</script>

<style scoped lang="scss">
.circle-number {
  height: 24px;
  width: 24px;
  border: 1px solid var(--main-color-grey-disabled);
  background-color: var(--main-color-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-shrink: 0;

  &._success {
    border: 1px solid var(--main-color-green);
  }

  &._warning {
    border: 1px solid var(--main-warning-color);
  }

  &._danger {
    border: 1px solid var(--main-red-color);
  }

  &._grey {
    border: 1px solid var(--main-color-black);
  }
}

.circle-number__content {
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
}
</style>
